import React, { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from '../supabaseClient'; // Import Supabase client
import { useLocation } from 'react-router-dom';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);


const formatNumberClaim = (num) => {
  if (num < 100000) {
    return new Intl.NumberFormat().format(num).replace(/,/g, " ");
  } else if (num < 1000000) {
    return new Intl.NumberFormat().format(num).replace(/,/g, " ");
  } else {
    return (num / 1000000).toFixed(2).replace(".", ".") + " M";
  }
};

const updateReferralsArray = async (userId, referralData) => {
  try {
    const { data: userData, error } = await supabase
      .from('telegramUsers')
      .select('referrals')
      .eq('userId', userId)
      .single();

    if (error) throw error;

    const updatedReferrals = [...(userData.referrals || []), referralData];

    await supabase
      .from('telegramUsers')
      .update({ referrals: updatedReferrals })
      .eq('userId', userId);
  } catch (error) {
    console.error("Error updating referrals array:", error);
  }
};


const updateReferrals = async (userId) => {
  try {
    // Fetch user data to access existing referrals
    const { data: userData, error } = await supabase
      .from('telegramUsers')
      .select('*')
      .eq('userId', userId)
      .single();

    if (error) throw error;

    const referrals = userData.referrals || [];
    const userBalance = userData.balance || 0; // Current user balance
    const previousRefBonus = userData.refBonus || 0; // Previously calculated refBonus

    // Fetch and update each referral's balance
    const updatedReferrals = await Promise.all(
      referrals.map(async (referral) => {
        const { data: referralData } = await supabase
          .from('telegramUsers')
          .select('balance')
          .eq('userId', referral.userId)
          .single();

        return {
          ...referral,
          balance: referralData.balance || 0, // Ensure balance is updated or set to 0 if undefined
        };
      })
    );

    // Update the user's `referrals` field with the latest balances
    await supabase
      .from('telegramUsers')
      .update({ referrals: updatedReferrals })
      .eq('userId', userId);

    // Calculate total earnings from updated referrals
    const totalEarnings = updatedReferrals.reduce((acc, curr) => acc + curr.balance, 0);

    // Calculate the new referral bonus (5% of total earnings)
    const newRefBonus = parseFloat((totalEarnings * 0.05).toFixed(8));

    // Check if there is an increase in refBonus
    if (newRefBonus > previousRefBonus) {
      const bonusDifference = newRefBonus - previousRefBonus;
      const updatedBalance = parseFloat(userBalance) + bonusDifference;

      // Update Supabase with the new refBonus and updated balance
      await supabase
        .from('telegramUsers')
        .update({
          refBonus: newRefBonus,
          balance: updatedBalance,
          lastActive: new Date(),
        })
        .eq('userId', userId);
    }
  } catch (error) {
    console.error('Error updating referral balances dynamically:', error);
  }
};


export const UserProvider = ({ children }) => {
  const [balance, setBalance] = useState(0);
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingTwo, setLoadingTwo] = useState(true);
  const [refBonus, setRefBonus] = useState(0);
  const [manualTasks, setManualTasks] = useState([]);
  const [advertTasks, setAdvertTasks] = useState([]);
  const [userAdvertTasks, setUserAdvertTasks] = useState([]);
  const [userManualTasks, setUserManualTasks] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [claimedReferralRewards, setClaimedReferralRewards] = useState([]);
  const [referrals, setReferrals] = useState([]);
  const telegramUser = window.Telegram.WebApp.initDataUnsafe?.user;
  const [fullName, setFullName] = useState('');
  const [username, setUsername] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [isAddressSaved, setIsAddressSaved] = useState(false); // State to track if address is saved
  const [checker, setChecker] = useState(false);
  const [premium, setPremium] = useState(false);
  const [taskPoints, setTaskPoints] = useState(0);
  const [slotPoints, setSlotPoints] = useState(0);
  const [premiumReward, setPremiumReward] = useState(0);
  const [checkinRewards, setCheckinRewards] = useState(0);
  const [lastCheckIn, setLastCheckIn] = useState(null);
  const [checkInDays, setCheckInDays] = useState([]);
  const [error, setError] = useState(null);
  const [showStartOverModal, setShowStartOverModal] = useState(false);
  const [showClaimModal, setShowClaimModal] = useState(false);
  const [miningPower, setMiningPower] = useState(0);
  const [leaderBoard, setLeaderBoard] = useState([]);
  const [mineLeaderBoard, setMineLeaderBoard] = useState([]);
  const [miningTotal, setMiningTotal] = useState(0);
  const [activeUserRank, setActiveUserRank] = useState(null);
  const [activeMineUserRank, setActiveMineUserRank] = useState(null);
  const [tonTransactions, setTonTransactions] = useState(0);
  const [tonTasks, setTonTasks] = useState(false);
  const location = useLocation();
  const [openInfoThree, setOpenInfoThree] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const [selectedExchange, setSelectedExchange] = useState({id: 'selectex', icon: '/exchange.svg', name: 'Select exchange'});
  const [youtubeTasks, setYoutubeTasks] = useState([]);
  const [userYoutubeTasks, setUserYoutubeTasks] = useState([]);
  const [hasVisitedBefore, setHasVisitedBefore] = useState(false);

  const fetchData = async (userId) => {
    if (!userId) return;
  
    try {
      // Fetch the active user's data
      const { data: userData, error } = await supabase
        .from('telegramUsers')
        .select('*')
        .eq('userId', userId)
        .single();

      if (error) throw error;

      const userBalance = userData.balance;
      const userMiningTotal = userData.miningTotal;

      // Set all other user data
      setBalance(userBalance);
      setClaimedReferralRewards(userData.claimedReferralRewards || []);
      setLastCheckIn(userData.lastCheckIn);
      setCheckInDays(userData.checkInDays || []);
      setUsername(userData.username);
      setTonTasks(userData.tonTasks);
      setTonTransactions(userData.tonTransactions);
      setSelectedExchange(userData.selectedExchange);
      setWalletAddress(userData.address);
      setIsAddressSaved(userData.isAddressSaved);
      setMiningTotal(userData.miningTotal);
      setMiningPower(userData.miningPower);
      setPremium(userData.isPremium);
      setTaskPoints(userData.taskPoints);
      setSlotPoints(userData.slotPoints);
      setPremiumReward(userData.premiumReward);
      setUserYoutubeTasks(userData.youtubeTasks || []);
      setCheckinRewards(userData.checkinRewards);
      setFullName(userData.fullName);
      setId(userData.userId);
      setRefBonus(userData.refBonus || 0);
      setCompletedTasks(userData.tasksCompleted || []);
      setUserManualTasks(userData.manualTasks || []);
      setUserAdvertTasks(userData.advertTasks || []);
      setReferrals(userData.referrals || []);
  
      // Calculate active user rank
      const { count: userCount } = await supabase
        .from('telegramUsers')
        .select('*', { count: 'exact' })
        .gt('balance', userBalance);

      const activeUserRank = userCount + 1;
      setActiveUserRank(activeUserRank); // Set the active user rank

      // Calculate active mine user rank
      const { count: mineUserCount } = await supabase
        .from('telegramUsers')
        .select('*', { count: 'exact' })
        .gt('miningTotal', userMiningTotal);

      const activeMineUserRank = mineUserCount + 1;
      setActiveMineUserRank(activeMineUserRank); // Set the active user rank

      // Continue fetching other data
      const { data: tasksData, error: tasksError } = await supabase
        .from('tasks')
        .select('*');

      if (tasksError) throw tasksError;
      setTasks(tasksData);

      const { data: leadersData, error: leadersError } = await supabase
        .from('leaderBoard')
        .select('*')
        .order('balance', { ascending: false });

      if (leadersError) throw leadersError;
      setLeaderBoard(leadersData);

      const { data: mineLeadersData, error: mineLeadersError } = await supabase
        .from('mineLeaderBoard')
        .select('*')
        .order('balance', { ascending: false });

      if (mineLeadersError) throw mineLeadersError;
      setMineLeaderBoard(mineLeadersData);

      const { data: manualTasksData, error: manualTasksError } = await supabase
        .from('manualTasks')
        .select('*');

      if (manualTasksError) throw manualTasksError;
      setManualTasks(manualTasksData);

      const { data: advertTasksData, error: advertTasksError } = await supabase
        .from('advertTasks')
        .select('*');

      if (advertTasksError) throw advertTasksError;
      setAdvertTasks(advertTasksData);

      // Fetch youtubeTasks
      const { data: youtubeTasksData, error: youtubeTasksError } = await supabase
        .from('youtubeTasks')
        .select('*');

      if (youtubeTasksError) throw youtubeTasksError;
      setYoutubeTasks(youtubeTasksData);

    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    setLoading(false);
  };


// Inside UserProvider component
useEffect(() => {
  // Set up a periodic update for referral balances
  const intervalId = setInterval(() => {
    if (id) {
      updateReferrals(id);
    }
  }, 10 * 60 * 1000); // Update every 10 minutes

  return () => clearInterval(intervalId); // Clean up on component unmount
}, [id]);

// Add this after the other useEffects
useEffect(() => {
  sendUserData();
  // eslint-disable-next-line
}, []);

useEffect(() => {
  if (id) {
    fetchData(id);
  }
  // eslint-disable-next-line 
}, [id]);




  useEffect(() => {
    if (id) {
      fetchData(id);
    }
    // eslint-disable-next-line 
  }, [id]);

  const sendUserData = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    let referrerId = queryParams.get("ref");
    if (referrerId) {
      referrerId = referrerId.replace(/\D/g, "");
    }
  
    if (telegramUser) {
      const { id: userId, username, first_name: firstName, last_name: lastName } = telegramUser;
      const finalUsername = username || `${firstName}_${userId}`;
      const fullNamed = `${firstName} ${lastName}`;
  
      try {
        // Fetch or create the user
        const { data: userData, error } = await supabase
          .from('telegramUsers')
          .select('*')
          .eq('userId', userId.toString())
          .single();
  
        if (error) {
          // User does not exist; create a new one
          const newUser = {
            userId: userId.toString(),
            username: finalUsername,
            firstName: firstName,
            lastName: lastName,
            fullName: fullNamed,
            totalBalance: 0,
            isAddressSaved: false,
            address: '',
            balance: 0,
            lastActive: new Date(),
            refereeId: referrerId || null,
            referrals: [],
            miningPower: 400,
            miningTotal: 0,
          };
  
          const { error: insertError } = await supabase
            .from('telegramUsers')
            .insert([newUser]);
          if (insertError) throw insertError;
  
          setFullName(fullNamed);
          setMiningPower(newUser.miningPower);
          setMiningTotal(newUser.miningTotal);
          setId(userId.toString());
        }
  
        // Referral logic
        if (referrerId) {
          const { data: referrerData, error: referrerError } = await supabase
            .from('telegramUsers')
            .select('referrals')
            .eq('userId', referrerId)
            .single();
            await updateReferralsArray(referrerId, referrerData);
          if (referrerError) {
            console.error("Referrer not found:", referrerError);
          } else {
            // Check if userId is already in referrer's referrals
            const existingReferral = referrerData.referrals?.find(
              (ref) => ref.userId === userId.toString()
            );
  
            if (!existingReferral) {
              // Add new referral
              const newReferral = {
                userId: userId.toString(),
                username: finalUsername,
                balance: 0,
                level: { id: 1, name: 'Bronze', imgUrl: '/bronze.webp' },
              };
              const updatedReferrals = [...(referrerData.referrals || []), newReferral];
  
              // Update the referrer with the new referrals array
              const { error: updateError } = await supabase
                .from('telegramUsers')
                .update({ referrals: updatedReferrals })
                .eq('userId', referrerId);
  
              if (updateError) {
                console.error("Error updating referrer's referrals:", updateError);
              } else {
                console.log("Referral saved successfully.");
              }
            } else {
              console.log("Referral already exists.");
            }
          }
        }
  
        setInitialized(true);
        fetchData(userId.toString());
  
      } catch (error) {
        console.error('Error saving user in Supabase:', error);
      }
    }
  };
  

  const updateActiveTime = async (userId) => {
    try {
      await supabase
        .from('telegramUsers')
        .update({ lastActive: new Date() })
        .eq('userId', userId);
      console.log('Active Time Updated');
    } catch (error) {
      console.error('Error updating Active Time:', error);
    }
  }

  const updateReferrals = async (userId) => {
    try {
      const { data: userData, error } = await supabase
        .from('telegramUsers')
        .select('*')
        .eq('userId', userId)
        .single();
      
      if (error) throw error;

      const referrals = userData.referrals || [];
      const userBalance = userData.balance || 0; // Get the current user balance
      const previousRefBonus = userData.refBonus || 0; // Get the previously calculated refBonus
  
      // Fetch updated referral data (with balances)
      const updatedReferrals = await Promise.all(
        referrals.map(async (referral) => {
          const { data: referralData } = await supabase
            .from('telegramUsers')
            .select('*')
            .eq('userId', referral.userId)
            .single();

          return {
            ...referral,
            balance: referralData.balance || 0, // Ensure balance is not undefined
          };
        })
      );
  
      // Update the referrals field in Supabase
      await supabase
        .from('telegramUsers')
        .update({ referrals: updatedReferrals })
        .eq('userId', userId);
  
      // Calculate total earnings from referrals
      const totalEarnings = updatedReferrals.reduce((acc, curr) => acc + curr.balance, 0);
      console.log('Total Earnings from Referrals:', totalEarnings);
  
      // Calculate the new referral bonus (5% of total earnings)
      const newRefBonus = parseFloat((totalEarnings * 0.05).toFixed(8)); // Keep precision, convert to number
      console.log('Calculated new refBonus:', newRefBonus);
  
      // Check if there is a difference between the new and old refBonus
      if (newRefBonus > previousRefBonus) {
        // Calculate the difference between new and old refBonus
        const bonusDifference = newRefBonus - previousRefBonus;
        console.log('Bonus difference:', bonusDifference);
  
        // Only add the bonus difference to the user's current balance
        const updatedBalance = parseFloat(userBalance) + bonusDifference;
        console.log('Updated totalBalance (with bonusDifference):', updatedBalance);
  
        // Update Supabase with the new refBonus and updated balance
        await supabase
          .from('telegramUsers')
          .update({
            refBonus: newRefBonus, // Store the new calculated refBonus
            balance: updatedBalance, // Update the user's total balance with the bonus difference
            lastActive: new Date(), // Update last active time
          })
          .eq('userId', userId);
  
        console.log('Supabase updated with new refBonus and totalBalance.');
      } else {
        console.log('No difference in refBonus, no balance update required.');
      }
  
    } catch (error) {
      console.error('Error updating Supabase with dynamic refBonus and totalBalance:', error);
    }
  };

  useEffect(() => {
    setChecker(false);
    if (id) {
      // Check if the user has visited before using localStorage
      const visited = localStorage.getItem('hasVisitedBefore');
      if ((balance > 0) && visited) {
        // User has visited before, no need to show the welcome message
        setHasVisitedBefore(true);
      } else {
        // User is visiting for the first time, show the welcome message
        setChecker(true);
        // Set the item in localStorage to mark the user as visited
        localStorage.setItem('hasVisitedBefore', 'true');
      }
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    const checkLastCheckIn = async () => {
      if (!id) return;
  
      try {
        const { data: userData, error } = await supabase
          .from('telegramUsers')
          .select('lastCheckIn, checkInDays')
          .eq('userId', id)
          .single();
  
        if (error) throw error;
  
        const now = new Date();
        const todayMidnight = new Date(now);
        todayMidnight.setHours(0, 0, 0, 0);
  
        const lastCheckInDate = userData.lastCheckIn ? new Date(userData.lastCheckIn) : null;
  
        if (lastCheckInDate) {
          const lastCheckInMidnight = new Date(lastCheckInDate);
          lastCheckInMidnight.setHours(0, 0, 0, 0);
  
          const daysSinceLastCheckIn = Math.floor((todayMidnight - lastCheckInMidnight) / (1000 * 60 * 60 * 24));
  
          if (daysSinceLastCheckIn === 1) {
            // User checked in yesterday, continue streak
            setShowClaimModal(true);
          } else if (daysSinceLastCheckIn > 1) {
            // Missed multiple days, reset streak
            const resetCheckInDays = [1];
  
            await supabase
              .from('telegramUsers')
              .update({
                lastCheckIn: now.toISOString(),
                checkInDays: resetCheckInDays,
              })
              .eq('userId', id);
  
            setCheckInDays(resetCheckInDays); // Reset streak locally
            setShowStartOverModal(true); // Show "Start Over" modal
          }
        } else {
          // First check-in
          setShowClaimModal(true);
        }
      } catch (err) {
        console.error('Error during initial check-in:', err);
        setError('An error occurred while checking your last check-in.');
      }
    };
  
    checkLastCheckIn();
  }, [id, setShowClaimModal, setShowStartOverModal, setCheckInDays, setError]);
    
  

  useEffect(() => {
    sendUserData();
    // eslint-disable-next-line 
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    const rewards = document.getElementById('reels');
    const rewardsTwo = document.getElementById('reels2');

    if (location.pathname.startsWith('/airdrop') || location.pathname.startsWith('/checkin')) {
      rewards.style.background = "#108f7f";
      rewards.style.color = "#000";
      rewards.style.height = "34px";
      rewards.style.marginTop = "4px";
      rewards.style.paddingLeft = "6px";
      rewards.style.paddingRight = "6px";
      rewards.style.borderRadius = "24px";
      rewardsTwo.style.filter = "brightness(0.1)";
    } else {
      rewards.style.background = "";
      rewards.style.color = "";
      rewards.style.height = "";
      rewards.style.marginTop = "";
      rewards.style.paddingLeft = "";
      rewards.style.paddingRight = "";
      rewards.style.borderRadius = "";
      rewardsTwo.style.filter = ""
    }
  }, [location.pathname]);

  return (
    <UserContext.Provider value={{ balance, setBalance, openInfoThree, setOpenInfoThree, youtubeTasks, setYoutubeTasks, userYoutubeTasks, setUserYoutubeTasks, fullName, selectedExchange, setSelectedExchange, leaderBoard, tonTasks, setTonTasks, username, tonTransactions, setTonTransactions, activeMineUserRank, setActiveMineUserRank, setUsername, activeUserRank, setActiveUserRank, mineLeaderBoard, setMineLeaderBoard, miningTotal, setMiningTotal, setLeaderBoard, miningPower, setMiningPower, loadingTwo, setLoadingTwo, checkinRewards, setCheckinRewards, taskPoints, setTaskPoints, slotPoints, setSlotPoints, premiumReward, setPremiumReward, showStartOverModal, setShowStartOverModal, showClaimModal, setShowClaimModal, lastCheckIn, setLastCheckIn, checkInDays, setCheckInDays, error, setError, checker, setChecker, premium, setPremium, userAdvertTasks, setUserAdvertTasks, advertTasks, setAdvertTasks, setFullName, walletAddress, setWalletAddress, isAddressSaved, setIsAddressSaved, loading, setLoading, id, setId, sendUserData, refBonus, setRefBonus, manualTasks, setManualTasks, userManualTasks, setUserManualTasks, tasks, setTasks, completedTasks, setCompletedTasks, referrals, claimedReferralRewards, setClaimedReferralRewards, initialized, setInitialized }}>
      {children}
    </UserContext.Provider>
  );
};
